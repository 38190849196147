import { Theme } from './index'
import { getEnv } from './helpers'
import swfLogo from './logos/swf.svg'
import swfLogoWhiteOnBlue from './logos/swf-white-on-blue.svg'
import swfLogoBlack from './logos/swf-black.svg'
import healthAppImage from './images/swf-health-app.png'
import qrAppStore from './images/swf-appstore-qr.png'
import qrGooglePlay from './images/swf-googleplay-qr.png'
import footerDesktop from './images/swf-footer-desktop.jpg'
import footerTablet from './images/swf-footer-tablet.jpg'
import footerMobile from './images/swf-footer-mobile.jpg'
import desktopImage from './images/swf-banner-desktop.jpg'
import tabletImage from './images/swf-banner-tablet.jpg'
import mobileImage from './images/swf-banner-mobile.jpg'
import { getStoreUrls } from './helpers'
import { getBrandedModule } from './defaultModules'

const isProduction = getEnv() === 'prod'

const colors = {
  blue: '#004996',
  darkBlue: '#052D66',
  accentBlue: '#001A4D',
  white: '#FFFFFF',
  black: '#000000',
  aluminiumGray: '#EFEFEF',
  button: {
    color: '#004996',
    hover: '#052D66',
    disabled: '#DDDDDD',
  },
  text: {
    body: '#000000',
    headings: '#004996',
    link: '#004996',
    linkHover: '#052D66',
  },
  monochrome: {
    darkestGrey: '#282B2E',
    darkerGrey: '#5B5B5B',
    darkGrey: '#777777',
    mediumGrey: '#AAAAAA',
    lightGrey: '#DDDDDD',
    lighterGrey: '#F2F2F2',
    lightestGrey: '#F8F8F8',
  },
}

const primaryTile = {
  backgroundColor: colors.blue,
  hoverBackgroundColor: colors.darkBlue,
  color: colors.white,
}

const smallIcon = {
  style: {
    fontSize: '3em',
  },
}

const storeUrls = getStoreUrls('https://mysupport.swfkrantechnik.com/swf/en')
const warrantyUrl = 'https://swf-ext-prod.launchpad.cfapps.eu20.hana.ondemand.com/site/fiori'

const themeConfig: Theme = {
  brand: 'swf',
  portalName: 'MySupport',
  privacyPolicyLink: 'https://www.swfkrantechnik.com/en/data-protection/',
  cookiePolicyLink: 'https://www.swfkrantechnik.com/en/data-protection/',
  companyLink: 'https://www.swfkrantechnik.com',
  siteTermsLink: '/terms-and-conditions/alpha-portal/latest.default.html',
  fontFamily: 'prometo',
  footerCopyrightId: 'swf_copyright',
  footerColor: colors.black,
  footerBackground: {
    desktopImage: footerDesktop,
    tabletImage: footerTablet,
    mobileImage: footerMobile,
  },
  headerLogo: swfLogoWhiteOnBlue,
  altHeaderLogo: swfLogo,
  menuLogo: swfLogo,
  headerLogoSize: '144px',
  altHeaderLogoSize: '120px',
  menuLogoSize: '120px',
  footerLogo: swfLogoBlack,
  footerLogoSize: '100px',
  primary: colors.blue,
  pageHeaderColor: colors.text.headings,
  headerColor: colors.monochrome.darkestGrey,
  headerBorder: true,
  headerBackgroundColor: colors.white,
  linkColor: colors.text.link,
  linkHoverColor: colors.text.linkHover,
  dashboardBanner: {
    desktopImage,
    tabletImage,
    mobileImage,
    title: 'MySupport',
    titleColor: colors.blue,
    borderColor: colors.monochrome.lightGrey,
  },
  healthApp: {
    qrAppStore,
    qrGooglePlay,
    image: healthAppImage,
    appStoreUrl: 'https://apps.apple.com/app/x-link/id1494895162',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.swf.xlink',
    moreInfoUrl: 'https://www.swfkrantechnik.com/en/new-app-swf-x-link',
  },
  header: {
    color: colors.white,
    backgroundColor: colors.black,
  },
  menuLink: {
    color: colors.monochrome.darkestGrey,
    hoverColor: colors.blue,
    hoverBackgroundColor: colors.monochrome.lightestGrey,
  },
  listItem: {
    titleColor: colors.monochrome.darkestGrey,
    descriptionColor: colors.monochrome.darkGrey,
  },
  button: {
    primary: {
      color: colors.button.color,
      textColor: colors.white,
      hover: colors.button.hover,
    },
    secondary: {
      color: colors.button.color,
      hover: colors.button.hover,
    },
  },
  registerUri: {
    storeDomain: 'https://mysupport.swfkrantechnik.com',
    storeAbbr:'swf'
  },
  modules: [
    // Main modules below:
    getBrandedModule('activationFlow'),
    getBrandedModule('dashboard'),
    getBrandedModule('contact'),
    getBrandedModule('modalMenu', {
      hideDescription: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('store', {
      to: storeUrls.storeUrl,
      title: 'swf_store',
      description: 'swf_store_description',
      secondary: true,
      external: true,
      showOnDashboard: false,
      tileProps: primaryTile,
    }),
    getBrandedModule('chainSystem', {
      title: 'chain_at_master',
      tileTitle: 'swf_chain_master',
      description: 'swf_chain_master_description',
      secondary: true,
      showOnDashboard: false,
      tileProps: primaryTile,
    }),
    getBrandedModule('documents', {
      to: storeUrls.documentsUrl,
      description: 'swf_documents_description',
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('news', {
      tileProps: primaryTile
    }),
    getBrandedModule('training', {
      to: 'https://www.swfkrantechnik.com/en/service/training-program',
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('leadTimes', {
      secondary: true,
      tileProps: primaryTile,
      tileIconProps: smallIcon,
    }),
    getBrandedModule('deliveryTracker', {
      to: storeUrls.deliveryTrackerUrl,
      description: 'swf_delivery_tracker_description',
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('eLearning', {
      secondary: true,
      description: 'swf_elearning_description',
      tileProps: primaryTile,
    }),
    getBrandedModule('serviceDocuments', {
      secondary: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('mySWF', {
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('businessDashboard', {
      secondary: true,
      hideDescription: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('healthApp', {
      title: 'x_link',
      secondary: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('hotjarDecrypt', {
      secondary: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('termsOfSale', {
      secondary: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('craneMaster', {
      title: 'swf_crane_master',
      menuItemTitle: 'crane_master',
      description: 'swf_crane_master_description',
      secondary: true,
      showOnDashboard: false,
      tileProps: primaryTile,
    }),
    getBrandedModule('warranty', {
      to: warrantyUrl,
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('mediaLibrary', {
      to: 'https://media.swfkrantechnik.com/',
      description: 'media_library_description',
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('troubleshooting', {
      secondary: true,
      title: 'X-Check',
      description: 'troubleshooting',
      tileProps: primaryTile,
    }),

    ...(!isProduction
      ? [
          getBrandedModule('assetFleet', {
            secondary: true,
            tileProps: primaryTile,
            title: 'swf_remote_monitoring',
            menuItemTitle: 'swf_remote_monitoring',
          }),
          getBrandedModule('myPartnersAndEquipment', {
            // IIFT4-933
            title: 'equipment',
            description: 'swf_my_partners_and_equipment_description',
            secondary: true,
            tileProps: primaryTile,
            tileIconProps: {
              size: '3x',
            },
          }),
          getBrandedModule('monitoredAsset', {
            title: 'swf_remote_monitoring',
            menuItemTitle: 'swf_remote_monitoring',
          }),
        ]
      : []),
  ],
}

export function loadTheme() {
  loadFontAndIcon()
  updatePageTitle()
}

export function loadFontAndIcon() {
  const head = document.getElementsByTagName('head')[0]
  const fontLinkEl = document.createElement('link')
  fontLinkEl.rel = 'stylesheet'
  fontLinkEl.type = 'text/css'
  fontLinkEl.href = 'https://use.typekit.net/fov3yxw.css'
  head.appendChild(fontLinkEl)

  const iconLinkEl = document.createElement('link')
  iconLinkEl.rel = 'icon'
  iconLinkEl.type = 'image/x-icon'
  iconLinkEl.href = './icons/swf.ico'
  head.insertBefore(iconLinkEl, head.firstChild)
}

function updatePageTitle() {
  const title = document.getElementsByTagName('title')[0]

  if (title) title.textContent = themeConfig.portalName!
}

export default themeConfig
